import React from "react";
import styled from "styled-components";
import { useLinkedIn } from "react-linkedin-login-oauth2";
import linkedin from "react-linkedin-login-oauth2/assets/linkedin.png";
import { LinkedInCallback } from "react-linkedin-login-oauth2";

function LinkedInPage() {
  console.log(`${window.location.origin}/demo/linkedin`)
  const { linkedInLogin } = useLinkedIn({
    clientId: "77a9vgfzycowx9",
    // redirectUri: `${window.location.origin}/demo/linkedin`,
    // redirectUri: `${window.location.origin}/linkedin`,
    redirectUri: `${window.location.origin}`,
    // redirectUri: `https://dev.example.com/auth/linkedin/callback`,
    // redirectUri:'http://192.168.19.198:3000/linkedin',
    state:"",
    onSuccess: (code) => {
      console.log(code);
      setCode(code);
      setErrorMessage("");
      // getAccesstToken3(code)
      getAccesstoken(code)
      // getAccessToken2(code)
    },
    scope: "r_emailaddress r_liteprofile",
    onError: (error) => {
      console.log(error);
      setCode("");
      setErrorMessage(error.errorMessage);
      
    },
  });
  const [code, setCode] = React.useState("");
  const [errorMessage, setErrorMessage] = React.useState("");

  const getAccessToken2 = (code) => {
    const body = new URLSearchParams([
      ['grant_type', 'authorization_code'],
      ['code', code],
      ['redirect_uri', 'http://localhost:3000/linkedin'],
      // ['redirect_uri', 'http://192.168.19.198:3000/linkedin'],
      // ['redirect_uri', 'https://dev.example.com/auth/linkedin/callback'],
      ['client_id', '77a9vgfzycowx9'],
      ['client_secret', 'oSZSsdyPyrtLlwsX']
    ]);
    const headers = new Headers({'Content-Type': 'application/x-www-form-urlencoded'}); 
    
    window.fetch('https://www.linkedin.com/oauth/v2/accessToken', {mode: 'no-cors', method: 'POST',body, headers})
    .then(response => response.json())
    .then(data => {
    // rest of code
    console.log("------------------")
    console.log(data)
    console.log("------------------")
  })
  }
  const getAccesstoken = (code) => {
    
    var details = {
      'grant_type': 'authorization_code',
      'code': code,
      // 'redirect_uri': `${window.location.origin}/demo/linkedin`,
      // 'redirect_uri': `${window.location.origin}/linkedin`,
      'redirect_uri': `${window.location.origin}`,
      'client_id': '77a9vgfzycowx9',
      'client_secret': 'oSZSsdyPyrtLlwsX'
  };
  console.log("-----------this is test----------------")
  console.log("https://www.linkedin.com/oauth/v2/accessToken");
  console.log(JSON.stringify(details))
  console.log("----------------------------------------")
  
  var formBody = [];
  for (var property in details) {
    var encodedKey = encodeURIComponent(property);
    var encodedValue = encodeURIComponent(details[property]);
    formBody.push(encodedKey + "=" + encodedValue);
  }
  formBody = formBody.join("&");
  
  // console.log
    fetch("https://www.linkedin.com/oauth/v2/accessToken", {
      mode: 'no-cors',
      method: 'POST',
      crossorigin: true,    
      headers: {'Content-Type': 'application/x-www-form-urlencoded','Access-Control-Allow-Origin':`${window.location.origin}`,'Origin':'https://staginghrms.naskay.in'},
      body: formBody
    })
      .then((response) => response.json())
      .then((responseJson) => {
        console.log("===========================");
        console.log(JSON.stringify(responseJson));
        console.log("===========================");
      })
      .catch((error) => {
        console.log("errrorrrrrr")
        console.error(error);
        console.log("errrorrrrrr")

      });
  }

  // const getAccesstToken3 = (code) => {
  //   "https://www.linkedin.com/oauth/v2/accessToken?grant_type=authorization_code&code="+code+"&redirect_uri=http://localhost:3000/linkedin&client_id=77a9vgfzycowx9&client_secret=oSZSsdyPyrtLlwsX"
  //   fetch("https://www.linkedin.com/oauth/v2/accessToken?grant_type=authorization_code&code="+code+"&redirect_uri=http://localhost:3000/applyForm&client_id=77a9vgfzycowx9&client_secret=oSZSsdyPyrtLlwsX", {
  //         mode: 'no-cors',
  //         method: 'GET',
  //         headers: {'Accept': 'application/json','Content-Type': 'application/json','Origin':'http://localhost:3000/applyForm'},
  //       })
  //         .then((response) => response.json())
  //         .then((responseJson) => {
  //           console.log("===========================");
  //           console.log(JSON.stringify(responseJson));
  //           console.log("===========================");
  //         })
  //         .catch((error) => {
            
  //           console.error(error);
  //         });
  // }

  return (
    <Wrapper>
      <img
        onClick={linkedInLogin}
        src={linkedin}
        alt="Log in with Linked In"
        style={{ maxWidth: "180px", cursor: "pointer" }}
      />

    <LinkedInCallback/>
      {!code && <div>No code</div>}
      {code && (
        <div>
          <div>Authorization Code: {code}</div>
          
          <div>
            Follow{" "}
            <Link
              target="_blank"
              href="https://docs.microsoft.com/en-us/linkedin/shared/authentication/authorization-code-flow?context=linkedin%2Fconsumer%2Fcontext&tabs=HTTPS#step-3-exchange-authorization-code-for-an-access-token"
              rel="noreferrer"
            >
              this
            </Link>{" "}
            to continue
          </div>
        </div>
      )}
      {errorMessage && <div>{errorMessage}</div>}
    </Wrapper>
  );
}

const Wrapper = styled.div`
  padding: 16px;
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const Link = styled.a`
  font-size: 20px;
  font-weight: bold;
`;

export default LinkedInPage;
